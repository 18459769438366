import { useEffect, useState } from "react";
import LocationSelector, {
  CITY_TO_NEIGHBORHOODS,
  NAMED_LOCATIONS,
} from "../../components/LocationSelector";
import DateBubbles from "../../components/DateBubble";
import { Date } from "../../types/Types";
import { fetchEventsForNamedLocation } from "../../connect-api/AppPromotionService";
import { PromotionEvent, PromotionsResponseV2 } from "../../connect-api/types";
import { EventCard } from "../../components/EventCard";
import { Grid } from "@mui/material";
import { SearchInput } from "../../components/SearchInput";
import Colors from "../../Colors";

export default function DiscoverHomePage() {
  // const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    document.title = "Fynd: Discover new events near you";
    // setIsMobile(window.innerWidth < 600);
  }, []);

  const [tagOptions, setTagOptions] = useState<string[]>([]);
  const [dateOptions, setDateOptions] = useState<Date[]>([]);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(dateOptions[0]);

  const [namedLocation, setNamedLocation] = useState(NAMED_LOCATIONS[0]);

  const [data, setData] = useState<PromotionsResponseV2>();

  const [events, setEvents] = useState<PromotionEvent[]>([]);

  useEffect(() => {
    fetchEventsForNamedLocation(namedLocation.shortCode, selectedTags).then(
      (data) => {
        setDateOptions(data.dates);
        setTagOptions(data.availableFilters);
        setSelectedDate(data.dates[0]);
        setData(data);
      },
    );
  }, [namedLocation, selectedTags]);

  useEffect(() => {
    if (!data || !data?.results || !selectedDate) {
      return;
    }
    setEvents(data.results[selectedDate.date].promos as PromotionEvent[]);
  }, [data, selectedDate]);

  return (
    <div style={{}}>
      <div
        style={{
          width: "100%",
          border: `1px solid ${Colors.headerDividerGray}`,
          marginBottom: 40,
        }}
      >
        <LocationSelector
          onNamedLocationChange={(newNamedLocation) => {
            console.log(newNamedLocation);
            setNamedLocation(newNamedLocation);
          }}
          onNeighborhoodChange={(newNeighborhood) => {
            console.log(newNeighborhood);
          }}
          namedLocation={namedLocation}
          neighborhood={CITY_TO_NEIGHBORHOODS["PHILADELPHIA"][0]}
        />
        <h5
          style={{
            textAlign: "center",
            marginTop: -50,
            marginBottom: 0,
            paddingBottom: 10,
            fontFamily: "Bozon",
            fontWeight: "bold",
            fontSize: 20,
            // underline purple
            borderBottom: `5px solid ${Colors.primary}`,
            borderRadius: 5,
            width: "20%",
            margin: "auto",
          }}
        >
          Specials & Events
        </h5>
      </div>
      {/* <EventFilterDropdown 
        filterOptions={tagOptions}
        selectedFilters={selectedTags}
        onChange={(filters) => setSelectedTags(filters)}
      /> */}
      <SearchInput
        onSearch={(query) => {
          console.log(query);
        }}
        includeFilters={true}
        filterProps={{
          filterOptions: tagOptions,
          selectedFilters: selectedTags,
          onChange: (filters) => setSelectedTags(filters),
        }}
      />
      <div
        style={{
          marginTop: 20,
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "12.5%",
          marginRight: "12.5%",
        }}
      >
        {/* <TagFilters
          tags={tagOptions}
          selectedTags={selectedTags}
          onTagSelected={function (tag: string): void {
            setSelectedTags([...selectedTags, tag]);
          }}
          onTagDeselected={function (tag: string): void {
            setSelectedTags(selectedTags.filter((t) => t !== tag));
          }}
        /> */}
        <DateBubbles
          dates={dateOptions.slice(0, 6)}
          selectedDate={selectedDate}
          onDateSelected={(date: Date) => {
            console.log(date);
            setSelectedDate(date);
          }}
        />
        <Grid
          container
          rowSpacing={3}
          columnSpacing={6}
          style={{
            marginTop: 20,
          }}
        >
          {events.map((event) => {
            return (
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <EventCard event={event} />
              </Grid>
            );

            // <EventCard event={event} />;
          })}
          {/* </div> */}
        </Grid>
      </div>
    </div>
  );
}

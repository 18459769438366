import { useEffect, useState } from "react";
import { Card, Divider, Grid } from "@mui/material";

import { getBusinessesInNamedLocation } from "../../../../connect-api/AppBusinessService";
import { definitions } from "../../../../connect-api/BusinessTypes";
import { useParams } from "react-router-dom";

type NeighborhoodListingProps = {
  namedLocation: string;
  neighborhood: string;
};

export default function NeighborhoodListing() {
  const { namedLocation, neighborhood } = useParams<NeighborhoodListingProps>();

  const [data, setData] = useState<definitions["Business"][]>();
  const [isMobile, setIsMobile] = useState(false);
  const [loc, setLoc] = useState("");

  const headerImageMap: Map<string, string> = new Map<string, string>([
    [
      "fishtown",
      "https://www.visitphilly.com/wp-content/uploads/2017/12/GirardAveFistown_M.Kennedy_02-2200x1237vp.jpg",
    ],
    [
      "center-city",
      "https://img2.10bestmedia.com/Images/Photos/190544/1486-l_54_990x660_201406020134.jpg",
    ],
  ]);

  useEffect(() => {
    if (!neighborhood || !namedLocation) {
      return;
    }

    setLoc(
      `${capitalize(neighborhood.replaceAll("-", " "))}, ${capitalize(
        namedLocation.replaceAll("-", " "),
      )}`,
    );
    //document.title = `Fynd: Top places to go in ${loc} `;

    getBusinessesInNamedLocation(
      namedLocation.toUpperCase(),
      neighborhood.replaceAll("-", "_").toUpperCase(),
    ).then((d) => {
      setData(d);
      document.title = `Fynd: Top ${d.length} places to go in ${`${capitalize(neighborhood.replaceAll("-", " "))}, ${capitalize(
        namedLocation.replaceAll("-", " "),
      )}`} `;
    });
  }, [namedLocation, neighborhood]);

  useEffect(() => {
    setIsMobile(window.innerWidth < 600);
  }, []);

  return (
    <div
      style={{
        width: "width",
        margin: "auto",
        marginTop: 0,
        color: "#6A54F3",
        fontFamily: "sans-serif",
      }}
    >
      <Card
        // style={{ borderRadius: 25, }}
        style={{ borderColor: "#6A54F3", borderWidth: 10 }}
      >
        {data && (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={headerImageMap.get(neighborhood || "")}
                alt={loc}
                height={isMobile ? 300 : 400}
                style={{
                  borderBottomLeftRadius: isMobile ? 0 : 50,
                  borderBottomRightRadius: isMobile ? 0 : 50,
                  objectFit: "cover",
                  boxShadow: "1, 100",
                }}
              />
            </div>
            <h1 style={{ textAlign: "center" }}>{`Top Places in ${loc}`}</h1>
            <Divider style={{ marginBottom: 10 }} />
            <Grid
              container
              columns={{ xs: 2, sm: 2, md: 3 }}
              rowSpacing={{ xs: 2, sm: 2, md: 3 }}
              columnSpacing={{ xs: 2, sm: 2, md: 3 }}
            >
              {data.map((business) => (
                <Grid item xs={1} sm={1} md={1} key={business.name}>
                  <a
                    href={`/business/${seoFriendlyUrlName(
                      business.name || "",
                    )}/${business.id}`}
                    style={{ textDecoration: "none" }}
                    key={business.name || ""}
                  >
                    <Card style={{ borderColor: "#6A54F3", borderWidth: 10 }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src={business.heroImageUrl}
                          alt={business.name}
                          height={isMobile ? 200 : 300}
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <h3 style={{ marginLeft: 5 }}>{business.name}</h3>
                    </Card>
                  </a>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </Card>
    </div>
  );
}

const capitalize = (s: string) => {
  return s.substring(0, 1).toUpperCase() + s.substring(1);
};

const seoFriendlyUrlName = (s: string) => {
  return encodeURIComponent(s.replaceAll(" ", "-"));
};

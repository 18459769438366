import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import Colors from "../Colors";
import {
  EventFilterDropdown,
  EventFilterDropdownProps,
} from "./EventFilterDropdown";

type SearchInputProps = {
  onSearch: (searchQuery: string) => void;
  includeFilters?: boolean;
  filterProps?: EventFilterDropdownProps;
};

export const SearchInput = (props: SearchInputProps) => {
  return (
    <div
      style={{
        maxWidth: "55%",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.includeFilters && props.filterProps && (
        <EventFilterDropdown {...props.filterProps} />
      )}
      <TextField
        sx={{
          borderRadius: 50,
          backgroundColor: Colors.gray5,
          padding: 2,
        }}
        type="search"
        variant="standard"
        // hide line
        fullWidth
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <Search sx={{ color: Colors.primary }} fontSize="large" />
            </InputAdornment>
          ),
          sx: {
            fontWeight: "normal",
            fontSize: 16,
            fontFamily: "Bozon",
          },
        }}
        placeholder="Search to find events near you"
      />
    </div>
  );
};

import { Button, Grid, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import Logo from "../assets/logo.svg";

export default function ListingNavbar(props: any) {
  if (props.minimal) {
    return (
      <div>
        <img
          style={{ marginLeft: 50 }}
          src={Logo}
          height={50}
          alt="fynd logo"
        />
        {props.children}
      </div>
    );
  }

  const NeighborhoodMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button style={{ color: "white" }} onClick={handleClick}>
          Neighborhoods
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "black" }}
            href="/philadelphia/fishtown"
          >
            <MenuItem>Fishtown, Philadelphia</MenuItem>
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            href="/philadelphia/center-city"
          >
            <MenuItem>Center City, Philadelphia</MenuItem>
          </Link>
        </Menu>
      </div>
    );
  };

  return (
    <div>
      <Grid
        style={{ backgroundColor: "#6A54F3" }}
        container
        columns={10}
        rowSpacing={{ xs: 2, sm: 2, md: 3 }}
        columnSpacing={{ xs: 2, sm: 2, md: 3 }}
      >
        <Grid item xs={4}>
          <a href="https://getfynd.app">
            <img
              src={"../../logo.svg"}
              height={50}
              alt="Fynd Logo"
              // style={{boxShadow: "0px 0px 5px #fff"}}
            />
          </a>
        </Grid>
        <Grid item xs={2}>
          <NeighborhoodMenu />
        </Grid>
      </Grid>
      {props.children}
    </div>
  );
}

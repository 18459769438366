export function areDatesOnSameDay(first: Date, second: Date): boolean {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

export function timeToString(date: Date): string {
  var s = "";
  var hours = date.getHours();
  if (hours > 12) {
    hours -= 12;
  }

  if (hours === 0) {
    s += "12";
  } else {
    s += hours;
  }

  s += ":";
  s += padZero(date.getMinutes());

  s += date.getHours() >= 12 ? "PM" : "AM";
  return s;
}

export function padZero(num: number): string {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
}

import { Link } from "@mui/material";
import { PromotionEvent, PromotionState } from "../connect-api/types";
import { CardHero } from "./Thumbnails";
import Colors from "../Colors";
import { timeToString } from "../utils/DateUtils";
import { FontWeights } from "../fonts/FontWeights";

const ROW_MARGIN = 6;

export function EventCard({ event }: { event: PromotionEvent }) {
  const startDate = new Date(event.startTime);
  const endDate = new Date(event.endTime);

  const color =
    event.state === PromotionState.ACTIVE ? Colors.green1 : Colors.orange1;

  return (
    <Link
      key={event.id}
      style={{
        backgroundColor: Colors.white1,
        width: 320,
        height: 275,
        borderRadius: 15,
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: color,
        padding: 6,
      }}
      underline="none"
      //   onPress={() => {
      //     navigation.navigate("EventDiscover", {
      //       event: option,
      //     });
      //   }}
      href={`/events/${event.name} @ ${event.businessName}/${event.type}-${event.id}`}
    >
      <CardHero image={event.heroImage} />
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            margin: 0,
            marginTop: ROW_MARGIN * 2,
          }}
        >
          <p
            style={{
              color: Colors.magenta1,
              fontSize: 16,
              fontWeight: "bold",
              width: "75%",
              fontFamily: "Bozon",
              margin: 0,
            }}
          >
            {event.name}
          </p>
          <div
            style={{
              backgroundColor: color,
              height: 24,
              width: 75,
              borderRadius: 14,
              columns: 1,
            }}
          >
            <p
              style={{
                color: "white",
                fontFamily: "Work Sans",
                fontSize: 10,
                fontWeight: FontWeights.medium,
                textAlign: "center",
                // center horizontally and vertically
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                margin: 0,
              }}
            >
              {prettyStates(event.state)}
            </p>
          </div>
        </div>
        <p
          style={{
            fontSize: 12,
            marginBlock: 1,
            fontWeight: FontWeights.medium,
            fontFamily: "Work Sans",
            color: Colors.black1,
            margin: 0,
            marginTop: ROW_MARGIN,
          }}
        >
          {event.businessName}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // height: 25,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontFamily: "Work Sans",
                fontSize: 12,
                color: Colors.lightGray,
                marginTop: ROW_MARGIN,
              }}
            >
              {`${
                startDate.getMonth() + 1
              }/${startDate.getDate()} | ${timeToString(
                startDate,
              )} - ${timeToString(endDate)}`}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {event.distance >= 0 && (
                <p
                  style={{
                    color: Colors.primary,
                  }}
                >
                  {formatDistance(event.distance)} mi
                </p>
              )}
            </div>
          </div>
          {/* <HeartFavoriteButton
                                    businessId={option.businessId}
                                    containerStyle={styles.likeIconCont}
                                    imageStyle={styles.likeIcon}
                                  /> */}
        </div>
      </div>
    </Link>
  );
}

export function formatDistance(distance: number): string {
  return parseFloat(distance.toFixed(2))
    .toPrecision(3)
    .replace(/\.?0+$/, "");
}

function prettyStates(state: PromotionState): string {
  switch (state) {
    case PromotionState.ACTIVE:
      return "Happening!";
    case PromotionState.ENDING_SOON:
      return "Ending Soon";
    case PromotionState.UPCOMING:
      return "Upcoming";
  }
  return "";
}

/*
 <InView
                            key={idx}
                            onChange={(inView) => {
                              if (inView)
                                TrackPromotionsInteraction(
                                  "view",
                                  option.businessId,
                                  option.id,
                                  option.type
                                );
                            }}
                          >
                            <Pressable
                              key={idx}
                              style={{
                                ...styles.specialEventsList,
                              }}
                              onPress={() => {
                                navigation.navigate("EventDiscover", {
                                  event: option,
                                });
                              }}
                            >
                              <Image
                                source={{
                                  uri: getThumbnail(option.heroImage),
                                  // expiresIn: 1_209_600,
                                }}
                                // cacheKey={option.heroImage}
                                style={{
                                  ...styles.Icon,
                                  width: 90,
                                  height: 100,
                                  marginRight: 15,
                                  borderTopLeftRadius: 20,
                                  borderBottomLeftRadius: 20,
                                }}
                              />
                              <View
                                style={{
                                  width: "70%",
                                }}
                              >
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: Colors.magenta1,
                                      fontSize: 14,
                                      fontWeight: "bold",
                                      width: "60%",
                                    }}
                                  >
                                    {option.name}
                                  </Text>
                                  <View
                                    style={{
                                      backgroundColor:
                                        option.state == "ACTIVE"
                                          ? Colors.green1
                                          : Colors.orange1,
                                      paddingVertical: 7,
                                      paddingHorizontal: 10,
                                      borderRadius: 50,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: "white",
                                      }}
                                    >
                                      {
                                        // TODO: Figure out how to resolve the "ENDING_SOON" state
                                        option.state == "ACTIVE"
                                          ? "Active Now!"
                                          : option.state === "UPCOMING"
                                          ? "Upcoming"
                                          : "Ending Soon"
                                      }
                                    </Text>
                                  </View>
                                </View>
                                <Text
                                  style={{
                                    fontSize: 12,
                                  }}
                                >
                                  {`@ ${option.businessName}`}
                                </Text>
                                <View
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    height: 25,
                                  }}
                                >
                                  <View
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: Colors.lightGray,
                                        marginVertical: 5,
                                      }}
                                    >
                                      {`${
                                        startDate.getMonth() + 1
                                      }/${startDate.getDate()} | ${timeToString(
                                        startDate
                                      )}-${timeToString(endDate)}`}
                                    </Text>
                                    <View
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      {option.distance >= 0 && (
                                        <Text
                                          style={{
                                            color: Colors.primary,
                                          }}
                                        >
                                          {formatDistance(option.distance)} mi
                                        </Text>
                                      )}
                                    </View>
                                  </View>
                                  <HeartFavoriteButton
                                    businessId={option.businessId}
                                    containerStyle={styles.likeIconCont}
                                    imageStyle={styles.likeIcon}
                                  />
                                </View>
                              </View>
                            </Pressable>
                          </InView>
*/

export function Thumbnail({ image }: { image: string }) {
  return (
    <img
      src={getThumbnailUrlBuilder(image)}
      alt="thumbnail"
      // cacheKey={option.heroImage}
      style={{
        width: 90,
        height: 100,
        marginRight: 15,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
      }}
    />
  );
}

export function CardHero({ image }: { image: string }) {
  return (
    <img
      src={getThumbnailUrlBuilder(image)}
      alt="thumbnail"
      // cacheKey={option.heroImage}
      style={{
        width: 321,
        height: 175,
        // marginTop: 6,
        // marginLeft: 6,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        objectFit: "cover",
      }}
    />
  );
}

export function getThumbnailUrlBuilder(image: string) {
  return `${image}/thumb.jpg`;
}

export function getIconUrlBuilder(image: string) {
  return `${image}/icon.jpg`;
}

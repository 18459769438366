import { useEffect, useState } from "react";
import {
  DayOfTheWeek,
  Promotion,
  PromotionType,
} from "../../../../../connect-api/types";
import { getBusinessCard } from "../../../../../connect-api/AppBusinessService";
import { getPromotion } from "../../../../../connect-api/AppPromotionService";
import { useParams } from "react-router-dom";

export default function EventListing() {
  let { id, name } = useParams();

  const [card, setCard] = useState<Promotion>();
  const [heroImage, setHeroImage] = useState<string>();
  // const [events, setEvents] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 600);
  }, []);

  useEffect(() => {
    if (!id || !name) {
      return;
    }

    document.title = `Fynd: ${name.replaceAll("-", " ")}`;

    getPromotion(
      id.split("-")[0] === "RECURRING"
        ? PromotionType.RECURRING
        : PromotionType.ONE_TIME,
      parseInt(id.split("-")[1]),
    ).then((data) => {
      console.log(data);
      setCard(data);
      if (data.imageUrl) {
        setHeroImage(data.imageUrl);
      } else {
        getBusinessCard(data.businessId).then((c) => {
          setHeroImage(c?.details?.heroImageUrl);
        });
      }
    });
  }, [id, name]);

  return (
    <div
      style={{
        width: "width",
        margin: "auto",
        marginTop: 0,
        fontFamily: "sans-serif",
        background: "white",
        color: "black",
        height: "100vh",
      }}
    >
      {heroImage && card && (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={heroImage}
              alt={card.promoName}
              height={isMobile ? 300 : 400}
              style={{
                borderBottomLeftRadius: isMobile ? 0 : 50,
                borderBottomRightRadius: isMobile ? 0 : 50,
                objectFit: "cover",
              }}
            />
          </div>
          <h1 style={{ textAlign: "center" }}>{card.promoName}</h1>
          {card.recurrenceDays && card.recurrenceDays.length > 0 && (
            <h3>{`Happens every ${card.recurrenceDays
              .map((d) => capitalize(d))
              .join(", ")}`}</h3>
          )}
          <h4>{`${new Date(card.startDateTime).toLocaleString("en-us", {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour12: true,
            hourCycle: "h12",
            minute: "2-digit",
            hour: "2-digit",
          })} - ${new Date(card.endDateTime).toLocaleString("en-us", {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour12: true,
            hourCycle: "h12",
            minute: "2-digit",
            hour: "2-digit",
          })}`}</h4>
          <blockquote>{card.description}</blockquote>
        </div>
      )}
    </div>
  );
}

const capitalize = (s: DayOfTheWeek) => {
  return (
    s.toLocaleString().substring(0, 1).toUpperCase() +
    s.toLocaleString().substring(1).toLowerCase()
  );
};

import axios from "axios";
import { API_HOST } from "./Constants";
import {
  Promotion,
  PromotionEvent,
  PromotionType,
  PromotionsResponseV2,
} from "./types";

export const getEventsForBusiness = async (businessId: number) => {
  let timezoneOffset = new Date().getTimezoneOffset() / -60;
  let start = Date.now();
  let end = start + 31556952000; // 31556952000 = 1 year in millis
  return axios
    .get<
      PromotionEvent[]
    >(`${API_HOST}/promotions/v2/fetch/business/${businessId}?start=${start}&end=${end}&timezone=${timezoneOffset}`)
    .then((r) => r.data);
};

export const getPromotion = async (type: PromotionType, id: number) => {
  let date = new Date();
  return axios
    .get<Promotion>(
      `${API_HOST}/promotions/v1/${type.toLocaleString()}/${id}?timezone=${-(
        date.getTimezoneOffset() / 60
      )}`,
      {
        headers: {
          // This a real token, but it is tied to an invalid "-1" user, effectively making it useless if it leaks
          ebtc: "e6ATA5rkGiMY5DeR",
          estc: "ff2f60a4d7613656e882bd9dca1cacaee9120991c740cb04653bc6b57d2235053d",
        },
      },
    )
    .then((r) => r.data);
};

export const fetchEventsForNamedLocation = async (
  location: string,
  filters: string[],
) => {
  let timezoneOffset = new Date().getTimezoneOffset() / -60;
  let start = Date.now();
  let end = start + 1209600000; // two weeks in millis = 1209600000
  return axios
    .get<PromotionsResponseV2>(
      `${API_HOST}/promotions/v2/fetch/named-location/${location}?customerId=-1&start=${start}&end=${end}&timezone=${timezoneOffset}${filtersToQueryString(filters)}`,
      {
        headers: {
          // This a real token, but it is tied to an invalid "-1" user, effectively making it useless if it leaks
          ebtc: "e6ATA5rkGiMY5DeR",
          estc: "ff2f60a4d7613656e882bd9dca1cacaee9120991c740cb04653bc6b57d2235053d",
        },
      },
    )
    .then((r) => r.data);
};

const filtersToQueryString = (filters: string[]) => {
  if (filters.length === 0) {
    return "";
  }
  return "&" + filters.map((f) => `filters=${f}`).join("&");
};

import { ButtonBase } from "@mui/material";

import Colors from "../Colors";
import { Date } from "../types/Types";

type DateBubblesProps = {
  dates: Date[];
  selectedDate: Date;
  onDateSelected: (date: Date) => void;
};

export default function DateBubbles({
  dates,
  selectedDate,
  onDateSelected,
}: DateBubblesProps) {
  return (
    <div
      style={{
        // display: "inline-block",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        maxWidth: "1000px",
        overflow: "auto",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
      }}
    >
      {dates.map((date) => {
        const isSelected = selectedDate === date;
        return (
          <ButtonBase
            key={date.date}
            onClick={() => {
              onDateSelected(date);
            }}
            style={{
              display: "inline-block",
              borderRadius: 20,
              border: "2px solid",
              paddingInline: 25,
              paddingBlock: 0,
              marginRight: 20,
              borderColor: `${isSelected ? Colors.primary : Colors.lightGray}`,
              backgroundColor: `${isSelected ? Colors.primary : "white"}`,
              // display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {/* <Chip label={date} /> */}
            <h2
              style={{
                color: `${isSelected ? "white" : Colors.lightGray}`,
                fontWeight: "normal",
                fontSize: 14,
                fontFamily: "Bozon",
                margin: 0,
                marginTop: 10,
              }}
            >
              {date.date}
            </h2>

            <h2
              style={{
                color: `${isSelected ? "white" : Colors.lightGray}`,
                fontFamily: "Bozon",
                fontSize: 16,
                fontWeight: "bolder",
                margin: 0,
                marginTop: 5,
                marginBottom: 10,
              }}
            >
              {date.dayOfTheWeek}
            </h2>
          </ButtonBase>
        );
      })}
    </div>
  );
}

/*
              let isSelected = option.date == selectedEventsDate.date;
                    return (
                      <Pressable
                        key={idx}
                        onPress={() => {
                          if (eventsData.dates) {
                            setSelectedEventsDate(eventsData.dates[idx]);
                            // TODO: Set Event Loading...
                          }
                        }}
                        style={{
                          ...styles.dateContainer,
                          borderColor: `${
                            isSelected ? Colors.primary : Colors.lightGray
                          }`,
                          backgroundColor: `${
                            isSelected ? Colors.primary : "white"
                          }`,
                        }}
                      >
                        <Text
                          style={{
                            ...styles.sortFilterBubbleText,
                            color: `${isSelected ? "white" : Colors.lightGray}`,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          {option.dayOfTheWeek}
                        </Text>
                        <Text
                          style={{
                            ...styles.sortFilterBubbleText,
                            color: `${isSelected ? "white" : Colors.lightGray}`,
                          }}
                        >
                          {option.date}
                        </Text>
                      </Pressable>
*/

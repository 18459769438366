import { KeyboardArrowDown, Tune } from "@mui/icons-material";
import { Button, Chip, Popover } from "@mui/material";
import { useState } from "react";
import Colors from "../Colors";

export type EventFilterDropdownProps = {
  filterOptions: string[];
  selectedFilters: string[];
  onChange: (filter: string[]) => void;
};

export const EventFilterDropdown = (props: EventFilterDropdownProps) => {
  const { filterOptions, selectedFilters, onChange } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      style={{
        marginRight: 10,
      }}
    >
      <Button
        sx={{
          backgroundColor: Colors.white1,
          color: Colors.lightGray,
          border: `1px solid ${Colors.lightGray}`,
          borderRadius: 50,
          paddingVertical: 1,
          paddingInlineEnd: 2,
        }}
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
      >
        <Tune sx={{ color: Colors.primary, marginRight: 1 }} />{" "}
        <p style={{ fontFamily: "Bozon", fontSize: 16, margin: 1 }}>Filter</p>
        <KeyboardArrowDown sx={{ color: Colors.primary }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        // round edges of the popover
        slotProps={{
          paper: {
            sx: {
              borderRadius: 10,
              border: `2px solid ${Colors.lightGray}`,
            },
          },
        }}
      >
        <div
          style={{
            padding: 5,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: 360,
          }}
        >
          {filterOptions.map((filter) => (
            <Chip
              key={filter}
              label={humanizeTagLabels(filter)}
              onClick={() => {
                const newFilters = selectedFilters.includes(filter)
                  ? selectedFilters.filter((f) => f !== filter)
                  : [...selectedFilters, filter];
                onChange(newFilters);
              }}
              style={{
                margin: 10,
                fontFamily: "Bozon",
                fontWeight: "bolder",
                fontSize: 16,
                backgroundColor: selectedFilters.includes(filter)
                  ? Colors.primary
                  : "white",
                color: selectedFilters.includes(filter)
                  ? "white"
                  : Colors.lightGray,
                // outline border
                border: `1px solid ${selectedFilters.includes(filter) ? Colors.primary : Colors.lightGray}`,
              }}
            />
          ))}
        </div>
      </Popover>
    </div>
  );
};

function humanizeTagLabels(tag: string): string {
  return tag
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

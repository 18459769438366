import axios from "axios";
import { definitions } from "./BusinessTypes";
import { API_HOST } from "./Constants";

export const getBusinessCard = async (id: number) => {
  let timezoneOffset = new Date().getTimezoneOffset() / -60;
  return axios
    .get<
      definitions["BusinessCard"]
    >(`${API_HOST}/business/v1/card/${id}?customerId=-1&timezone=${-timezoneOffset}`)
    .then((r) => r.data);
};

export const getBusinessesInNamedLocation = async (
  namedLocation: string,
  neighborhood: string,
) => {
  const ids = (
    await axios.get<number[]>(
      `${API_HOST}/business/v1/named-locations/${namedLocation}/business-ids${
        neighborhood ? `?neighborhood=${neighborhood}` : ""
      }`,
    )
  ).data;

  return axios
    .post<definitions["Business"][]>(
      `${API_HOST}/business/v1/fetch-set?customerId=-1`,
      {
        ids: ids,
      },
    )
    .then((r) => r.data);
};

// import logo from "./logo.svg";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ListingNavbar from "./pages/ListingNav";
import NeighborhoodListing from "./pages/listing/[namedLocation]/[neighborhood]";
import BusinessListing from "./pages/listing/business/[name]/[businessId]";
import posthog from "posthog-js";
import EventListing from "./pages/listing/events/[name]/[id]";
import DiscoverHomePage from "./pages/listing/Discover";

function App() {
  // Analytics
  posthog.init("phc_2d7NMEMAod2Ui6HaEEiSbqGc6NOpFtfUautiI7WZR45", {
    api_host: "https://app.posthog.com",
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <DiscoverHomePage />,
    },
    {
      path: "/:namedLocation/:neighborhood",
      element: <NeighborhoodListing />,
    },
    {
      path: "/business/:name/:businessId",
      element: <BusinessListing />,
    },
    {
      path: "/events/:name/:id",
      element: <EventListing />,
    },
  ]);

  return (
    <ListingNavbar minimal={true}>
      <RouterProvider router={router} />
    </ListingNavbar>
  );
}

export default App;

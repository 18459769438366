import { FunctionComponent, useEffect, useState } from "react";
import { getEventsForBusiness } from "../../../../../connect-api/AppPromotionService";
import {
  PromotionEvent,
  PromotionState,
} from "../../../../../connect-api/types";
import { definitions } from "../../../../../connect-api/BusinessTypes";
import { getBusinessCard } from "../../../../../connect-api/AppBusinessService";
import { Card, Chip, Grid } from "@mui/material";
import {
  CalendarTodayOutlined,
  ListOutlined,
  MapOutlined,
  PhoneIphoneOutlined,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";

type BusinessListingProps = {
  businessId: string;
  name: string;
};

export default function BusinessListing() {
  let { businessId, name } = useParams<BusinessListingProps>();

  // const [loading, setLoading] = useState(true);

  const [card, setCard] = useState<definitions["BusinessCard"]>();
  const [events, setEvents] = useState<PromotionEvent[]>([]);

  const [selectedTab, setSelectedTab] = useState("EVENTS");

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (!businessId || !name) {
      return;
    }
    const id = parseInt(businessId);
    document.title = `Fynd: ${(name || "").replaceAll("-", " ")}`;
    getBusinessCard(id).then(setCard);
    getEventsForBusiness(id).then(setEvents);
  }, [businessId, name]);

  useEffect(() => {
    if (events.length === 0) {
      setSelectedTab("DETAILS");
    } else {
      setSelectedTab("EVENTS");
    }
  }, [events]);

  useEffect(() => {
    setIsMobile(window.innerWidth < 600);
  }, []);

  return (
    <div
      style={{
        width: "width",
        margin: "auto",
        marginTop: 0,
        color: "#6A54F3",
        fontFamily: "sans-serif",
      }}
    >
      <div
        // style={{ borderRadius: 25, }}
        style={{ borderColor: "#6A54F3", borderWidth: 10 }}
      >
        {/* <Spinner isVisible={loading} /> */}
        {card && (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={card?.details?.heroImageUrl}
                alt={card?.details?.name}
                height={isMobile ? 300 : 400}
                style={{
                  borderBottomLeftRadius: isMobile ? 0 : 50,
                  borderBottomRightRadius: isMobile ? 0 : 50,
                  objectFit: "cover",
                  alignItems: "center",
                }}
              />
            </div>
            <h1 style={{ textAlign: "center" }}>{card?.details?.name}</h1>
            {isMobile && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "50%" }}>
                  <div
                    onClick={() => setSelectedTab("EVENTS")}
                    style={{
                      color: "#6A54F3",
                      cursor: "pointer",
                      textDecoration:
                        selectedTab === "EVENTS" ? "underline" : "none",
                    }}
                  >
                    <CalendarTodayOutlined />
                    Events
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setSelectedTab("DETAILS")}
                    style={{
                      color: "#6A54F3",
                      cursor: "pointer",
                      textDecoration:
                        selectedTab === "DETAILS" ? "underline" : "none",
                    }}
                  >
                    <ListOutlined />
                    Details
                  </div>
                </div>
              </div>
            )}
            {!isMobile && (
              <Grid
                container
                columns={3}
                rowSpacing={{ xs: 2, sm: 2, md: 3 }}
                columnSpacing={{ xs: 2, sm: 2, md: 3 }}
              >
                {(selectedTab === "EVENTS" || !isMobile) && (
                  <Grid item xs={2}>
                    <h3>Events</h3>
                    <Events events={events} />
                  </Grid>
                )}
                {(selectedTab === "DETAILS" || !isMobile) && (
                  <Grid item xs={1}>
                    <h3>Details</h3>
                    <Details card={card} />
                  </Grid>
                )}
              </Grid>
            )}
            {isMobile && (
              <>
                {selectedTab === "EVENTS" && <Events events={events} />}
                {selectedTab === "DETAILS" && <Details card={card} />}
              </>
            )}
          </div>
        )}
        {/* </Card> */}
      </div>
    </div>
  );
}

const seoFriendlyUrlName = (s: string) => {
  return encodeURIComponent(s.replaceAll(" ", "-"));
};

type EventsProps = {
  events: PromotionEvent[];
};

const Events: FunctionComponent<EventsProps> = ({ events }) => {
  return (
    <div>
      {events.map((e) => (
        <a
          style={{ textDecoration: "none" }}
          href={`/events/${seoFriendlyUrlName(e.name)}/${e.type}-${e.id}`}
          key={e.name}
        >
          <Card style={{ margin: 10 }}>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <h3 style={{ width: "75%" }}>{e.name}</h3>
              <EventStatus status={e.state} />
            </div>
            <h4>{e.description}</h4>
            <h4>{`${new Date(e.startTime).toLocaleString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour12: true,
              hourCycle: "h12",
              minute: "2-digit",
              hour: "2-digit",
            })} - ${new Date(e.endTime).toLocaleString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour12: true,
              hourCycle: "h12",
              minute: "2-digit",
              hour: "2-digit",
            })}`}</h4>
          </Card>
        </a>
      ))}
    </div>
  );
};

type DetailsProps = {
  card: definitions["BusinessCard"];
};

const Details: FunctionComponent<DetailsProps> = ({ card }) => {
  return (
    <div>
      {card?.tags?.map((t) => (
        <Chip
          label={t?.tag?.toLowerCase().replaceAll("_", " ")}
          sx={{
            fontFamily: "sans-serif",
            textTransform: "capitalize",
            backgroundColor: "#6A54F3",
            borderColor: "#6A54F3",
            color: "white",
            margin: 1,
          }}
          key={t.tag}
        />
      ))}
      <a
        target="_blank"
        rel="noreferrer noopener"
        href={`https://www.google.com/maps/search/?api=1&query=${card?.details?.address}&query_place_id=${card?.details?.googlePlacesId}`}
        style={{ display: "flex" }}
      >
        <MapOutlined style={{ marginTop: 15, color: "#6A54F3" }} />
        <h3
          title="address"
          aria-label="address"
          style={{ textAlign: "left", paddingLeft: 10, color: "#6A54F3" }}
        >
          {card?.details?.address}
        </h3>
      </a>
      <br></br>
      {card?.details?.phone && card?.details?.phone !== "" && (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={`tel:${card?.details?.phone}`}
          style={{ display: "flex" }}
        >
          <PhoneIphoneOutlined style={{ marginTop: 15, color: "#6A54F3" }} />
          <h3
            title="phone"
            aria-label="phone"
            style={{ textAlign: "left", paddingLeft: 10, color: "#6A54F3" }}
          >
            {`(${card?.details?.phone.substring(
              0,
              3,
            )}) ${card?.details?.phone.substring(
              3,
              6,
            )}-${card?.details?.phone.substring(6, 10)}`}
          </h3>
        </a>
      )}
      <br></br>
      {card?.details?.description && card?.details?.description !== "" && (
        <h3
          title="description"
          aria-label="description"
          style={{ textAlign: "left", paddingLeft: 10 }}
        >
          {card?.details?.description}
        </h3>
      )}
      <br></br>
      <div style={{ textAlign: "left", paddingLeft: 10 }}>
        <h2>Hours</h2>
        <h3 style={{ display: "flex", flexDirection: "row" }}>
          <b style={{ width: "50%" }}>Sunday:</b>
          <TimeDisplay times={card?.hours?.SUNDAY || []} />
        </h3>
        <h3 style={{ display: "flex", flexDirection: "row" }}>
          <b style={{ width: "50%" }}>Monday:</b>
          <TimeDisplay times={card?.hours?.MONDAY || []} />
        </h3>
        <h3 style={{ display: "flex", flexDirection: "row" }}>
          <b style={{ width: "50%" }}>Tuesday:</b>
          <TimeDisplay times={card?.hours?.TUESDAY || []} />
        </h3>
        <h3 style={{ display: "flex", flexDirection: "row" }}>
          <b style={{ width: "50%" }}>Wednesday:</b>
          <TimeDisplay times={card?.hours?.WEDNESDAY || []} />
        </h3>
        <h3 style={{ display: "flex", flexDirection: "row" }}>
          <b style={{ width: "50%" }}>Thursday:</b>
          <TimeDisplay times={card?.hours?.THURSDAY || []} />
        </h3>
        <h3 style={{ display: "flex", flexDirection: "row" }}>
          <b style={{ width: "50%" }}>Friday:</b>
          <TimeDisplay times={card?.hours?.FRIDAY || []} />
        </h3>
        <h3 style={{ display: "flex", flexDirection: "row" }}>
          <b style={{ width: "50%" }}>Saturday:</b>
          <TimeDisplay times={card?.hours?.SATURDAY || []} />
        </h3>
      </div>
    </div>
  );
};

type TimeDisplayProps = {
  times: definitions["BusinessHours"][];
};

const TimeDisplay: FunctionComponent<TimeDisplayProps> = ({ times }) => {
  if (times === undefined) {
    return <Chip key="" label="Hours not Known" />;
  }
  if (
    times.length === 1 &&
    times[0].hourOpen === 0 &&
    times[0].hourClose === 23.99
  ) {
    return (
      <Chip
        label="Open 24 Hours"
        variant="filled"
        key=""
        sx={{
          backgroundColor: "#6A54F3",
          borderColor: "#6A54F3",
          color: "white",
        }}
      />
    );
  }

  if (
    times.length === 1 &&
    times[0].hourOpen === 0 &&
    times[0].hourClose === 0
  ) {
    return (
      <Chip
        label="Closed"
        key=""
        sx={{
          backgroundColor: "#6A54F3",
          borderColor: "#6A54F3",
          color: "white",
        }}
      />
    );
  }

  return (
    <div>
      {times.map((p) => (
        <Chip
          style={{
            backgroundColor: "#6A54F3",
            borderColor: "#6A54F3",
            color: "white",
          }}
          label={`${twentyFourHourToTwelveHourTime(
            p.hourOpen || 0,
          )}-${twentyFourHourToTwelveHourTime(p.hourClose || 0)}`}
          variant="filled"
          key={p.dayOfTheWeek}
        />
      ))}
    </div>
  );
};

type EventStatusProps = {
  status: PromotionState;
};

const EventStatus: FunctionComponent<EventStatusProps> = ({ status }) => {
  switch (status) {
    case PromotionState.ACTIVE:
      return (
        <Chip
          label="Happening Now"
          style={{ backgroundColor: "green", color: "white" }}
        />
      );
    case PromotionState.UPCOMING:
      return <Chip label="Upcoming" style={{ backgroundColor: "orange" }} />;
    default:
      return <></>;
  }
};

function twentyFourHourToTwelveHourTime(hours: number) {
  if (hours && hours === 23.99) {
    return "12:00 AM";
  }

  // if (!hours) {
  //   return "";
  // }

  var suffix = hours >= 12 ? "PM" : "AM";
  return parseTimeNumberToTimeString(((hours + 11) % 12) + 1) + suffix;
}

function parseTimeNumberToTimeString(n: number) {
  if (!n) return n;
  const a = Math.floor(n);
  const b = +((n % a) * 60).toFixed(2);
  const r = `${a > 9 ? a : `0${a}`}:${b > 9 ? b : `0${b}`}`;
  return r;
}

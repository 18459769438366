import { Date } from "../types/Types";
import { definitions } from "./BusinessTypes";

export interface PromotionEvent {
  id: number;
  type: PromotionType;
  businessId: number;
  businessName: string;
  name: string;
  description: string;
  heroImage: string;
  startTime: number;
  endTime: number;
  recurringEndDate: number;
  hoursOffset: number;
  distance: number;
  readableStartTime: string;
  readableEndTime: string;
  state: PromotionState;
}

export enum PromotionType {
  ONE_TIME = "ONE_TIME",
  RECURRING = "RECURRING",
}

export enum PromotionState {
  ACTIVE = "ACTIVE",
  ENDING_SOON = "ENDING_SOON",
  UPCOMING = "UPCOMING",
}

export interface Promotion {
  id: number;
  type: PromotionType;
  businessId: number;
  promoName: string;
  description: string;
  recurrenceDays: DayOfTheWeek[];
  imageUrl?: string;
  eventLink?: string;
  tags: definitions["TagSet"];
  startDateTime: number;
  endDateTime: number;
  recurringEndDate: number;
  timezoneOffset: number;
}

export enum DayOfTheWeek {
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
}

export interface PromotionsResponseResultItem {
  date: Date;
  promos: PromotionEvent[];
}

export interface PromotionsResponseV2 {
  results: any;
  dates: Date[];
  availableFilters: string[];
}

const Colors = {
  // light: {
  //   text: '#000',
  //   background: '#fff',
  //   tint: tintColorLight,
  //   tabIconDefault: '#ccc',
  //   tabIconSelected: tintColorLight,
  // },
  // dark: {
  //   text: '#fff',
  //   background: '#000',
  //   tint: tintColorDark,
  //   tabIconDefault: '#ccc',
  //   tabIconSelected: tintColorDark,
  // },
  primary: "#6A54F3",
  secondary: "#CBC2FF",
  black1: "#000000",
  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#F1F2F5",
  gray4: "#BDBDBD",
  gray5: "#F7F8FB",
  gray6: "#E5E5E5",
  headerDividerGray: "#C5CEE0",
  lightGray: "#8F9BB3",
  white1: "#FFFFFF",
  white2: "#FEFEFE",
  shadow1: "#000D07",
  shadow2: "#000A3E",
  error1: "#ED2E7E",
  error2: "#C30052",
  green1: "#00966D", // "Active Now" events tag
  orange1: "#F2994A", // "Upcoming" events tag
  magenta1: "#ED2E7E", // Specials & events ... events label
  uglySweaterGreen: "#2B8674",
  uglySweaterRed: "#FF5561",
};

export default Colors;

export const VenmoColors = {
  primary: "#3D95CE",
};

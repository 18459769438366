import { ArrowForwardIos, Close } from "@mui/icons-material";
import { ButtonBase, Modal } from "@mui/material";
import { useState } from "react";
import { Location } from "../types/Types";
import Colors from "../Colors";

export const NAMED_LOCATIONS = [
  // {
  //   displayName: "Boston, MA",
  //   shortCode: "BOSTON",
  // },
  {
    displayName: "Philadelphia, PA",
    shortCode: "PHILADELPHIA",
  },
];

export const CITY_TO_NEIGHBORHOODS = {
  PHILADELPHIA: [
    {
      displayName: "All Neighborhoods",
      shortCode: "NONE",
      image: "",
    },
    {
      displayName: "Fishtown",
      shortCode: "FISHTOWN",
      image:
        "https://www.visitphilly.com/wp-content/uploads/2017/12/GirardAveFistown_M.Kennedy_02-2200x1237vp.jpg",
    },
    {
      displayName: "Center City",
      shortCode: "CENTER_CITY",
      image: "",
    },
  ],
  BOSTON: [
    {
      displayName: "All Neighborhoods",
      shortCode: "NONE",
      image: "",
    },
  ],
};

export const NEW_NEIGHBORHOODS = [
  {
    displayName: "Chestnut Hill",
    shortCode: "CHESTNUT_HILL",
    image: "",
  },
  {
    displayName: "East Passyunk",
    shortCode: "EAST_PASSYUNK",
    image: "",
  },
  {
    displayName: "Manayunk",
    shortCode: "MANAYUNK",
    image: "",
  },
  {
    displayName: "Northern Liberties",
    shortCode: "NORTHERN_LIBERTIES",
    image: "",
  },
  {
    displayName: "Old City",
    shortCode: "OLD_CITY",
    image: "",
  },
  {
    displayName: "South Street",
    shortCode: "SOUTH_STREET",
    image: "",
  },
  {
    displayName: "University City",
    shortCode: "UNIVERSITY_CITY",
    image: "",
  },
];

type LocationSelectorProps = {
  onNamedLocationChange: (option: Location) => void;
  onNeighborhoodChange: (option: Location) => void;
  namedLocation: Location;
  neighborhood: Location;
};

export default function LocationSelector({
  onNamedLocationChange,
  onNeighborhoodChange,
  namedLocation,
  neighborhood,
}: LocationSelectorProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedNamedLocation, setSelectedNamedLocation] =
    useState(namedLocation);
  const [selectedNeighborhood, setSelectedNeighborhood] =
    useState(neighborhood);

  const [showNeighborhoodSelector, setShowNeighborhoodSelector] =
    useState(false);
  const [showCitySelector, setShowCitySelector] = useState(false);

  //   const [includeNewNeighborhoods, setIncludeNewNeighborhoods] = useState(false);
  const [includeNewNeighborhoods] = useState(false);

  // TODO: add feature flag ability
  // useEffect(() => {
  //   Feature.isEnabled("locationSelect:includeNewNeighborhoods", auth.user.id
  //   ).then((enabled) => {
  //     setIncludeNewNeighborhoods(enabled);
  //   });
  // }, []);

  return (
    <div
      style={{
        marginTop: 20,
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "10%",
        marginBottom: 0,
      }}
    >
      <Modal
        // animationType={"fade"}
        // transparent={true}
        open={modalVisible}
        onClose={() => {
          setModalVisible(!modalVisible);
        }}
        style={{
          width: "100%",
        }}
      >
        <div
          onClick={() => {
            // setModalVisible(!modalVisible);
          }}
          style={{
            width: "100%",
            marginTop: 0,
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: 5,
              background: "white",
            }}
          >
            <ButtonBase
              onClick={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <Close
                style={{
                  width: 30,
                  height: 30,
                  color: Colors.primary,
                }}
              />
            </ButtonBase>
          </div>
          <div
            style={{
              marginTop: 0,
              padding: 20,
              paddingTop: 76,
              height: 325,
              backgroundColor: "white",
              borderRadius: 20,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            {!showNeighborhoodSelector && !showCitySelector && (
              <div>
                <h2
                  style={{
                    color: Colors.primary,
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  Select City
                </h2>
                <ButtonBase
                  onClick={() => {
                    //setModalVisible(!modalVisible);
                    setShowCitySelector(true);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: 24,
                        fontWeight: "bold",
                        marginRight: 8,
                      }}
                    >
                      {selectedNamedLocation.displayName}
                    </h3>
                    <ArrowForwardIos
                      style={{
                        width: 13,
                        height: 18,
                      }}
                    />
                  </div>
                </ButtonBase>
              </div>
            )}
            {!showNeighborhoodSelector && !showCitySelector && (
              <div>
                <h3
                  style={{
                    color: Colors.primary,
                    fontSize: 14,
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  Select Neighborhood
                </h3>
                <ButtonBase
                  onClick={() => {
                    //setModalVisible(!modalVisible);
                    setShowNeighborhoodSelector(true);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: 24,
                        fontWeight: "bold",
                        marginRight: 8,
                      }}
                    >
                      {selectedNeighborhood.displayName}
                    </h3>
                    <ArrowForwardIos
                      style={{
                        width: 13,
                        height: 18,
                      }}
                    />
                  </div>
                </ButtonBase>
              </div>
            )}
            {showCitySelector && (
              <div>
                {selectorModalScreen({
                  locationList: NAMED_LOCATIONS,
                  title: "Select City",
                  onBack: () => setShowCitySelector(false),
                  onLocationChange: (option) => {
                    if (onNamedLocationChange) onNamedLocationChange(option);
                    setShowCitySelector(false);
                    setSelectedNamedLocation(option);
                  },
                })}
              </div>
            )}
            {showNeighborhoodSelector && (
              <div>
                {selectorModalScreen({
                  locationList: [
                    ...(CITY_TO_NEIGHBORHOODS[
                      selectedNamedLocation.shortCode as keyof typeof CITY_TO_NEIGHBORHOODS
                    ] || []),
                    ...(includeNewNeighborhoods ? NEW_NEIGHBORHOODS : []),
                  ],
                  title: "Select Neighborhood",
                  onBack: () => setShowNeighborhoodSelector(false),
                  onLocationChange: (option) => {
                    if (onNeighborhoodChange) onNeighborhoodChange(option);
                    setShowNeighborhoodSelector(false);
                    setSelectedNeighborhood(option);
                    // Location.setSelectedNeighborhood(option);
                    setModalVisible(false);
                  },
                })}
              </div>
            )}
          </div>
        </div>
      </Modal>
      <h2
        style={{
          color: Colors.lightGray,
          fontSize: 16,
          marginBottom: 0,
          fontFamily: "Bozon",
          fontWeight: "normal",
        }}
      >
        EXPLORE
        {/* {selectedNamedLocation.displayName} */}
      </h2>
      <ButtonBase
        onClick={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: -25,
          }}
        >
          <h3
            style={{
              fontSize: 32,
              fontFamily: "Bozon",
              fontWeight: "bold",
              marginRight: 8,
            }}
          >
            {selectedNamedLocation.displayName}
            {/* {selectedNeighborhood.displayName} */}
          </h3>
          <ArrowForwardIos
            style={{
              width: 17,
              height: 27,
              color: Colors.primary,
            }}
          />
        </div>
      </ButtonBase>
    </div>
  );
}

const locationSelectorList = ({
  locationList,
  onLocationChange,
}: {
  locationList: any[];
  onLocationChange: (option: any) => void;
}) => {
  return locationList.map((option, idx) => {
    return (
      <ButtonBase
        key={idx}
        onClick={() => {
          if (onLocationChange) onLocationChange(option);
        }}
      >
        <h2
          style={{
            fontSize: 24,
            fontWeight: "bold",
            marginRight: 8,
            marginTop: 25,
          }}
        >
          {option.displayName}
        </h2>
      </ButtonBase>
    );
  });
};

const selectorModalScreen = ({
  locationList,
  onLocationChange,
  onBack,
  title,
}: {
  locationList: any[];
  onLocationChange: (option: any) => void;
  onBack: () => void;
  title: string;
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "white",
          padding: 10,
        }}
      >
        <h2
          style={{
            position: "absolute",
            height: 17,
            left: 95,
            top: -5,
            fontFamily: "Bozon-Bold",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: 14,
            lineHeight: 17,
            letterSpacing: 0.05,
            color: "#000000",
            marginTop: 20,
            backgroundColor: "white",
          }}
        >
          {title}
        </h2>
        <ButtonBase style={{ padding: 5 }} onClick={onBack}>
          <h3
            style={{
              position: "absolute",
              height: 16,
              left: 5,
              top: -5,
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: 14,
              lineHeight: 16,
              color: "#6A54F3",
              marginTop: 20,
            }}
          >{`<- Back`}</h3>
        </ButtonBase>
      </div>
      <div
        style={{
          height: 200,
          // make scrollable
          overflow: "scroll",
        }}
      >
        {locationSelectorList({
          locationList: locationList,
          onLocationChange: onLocationChange,
        })}
      </div>
    </div>
  );
};
